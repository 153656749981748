import $ from 'jquery';

// Open Sub Menu
$('.drp_btn').click(function () {
  $(this).siblings('.sub_menu').slideToggle();
});

// Preloader JS

function preloader_fade () {
  $('#preloader').fadeOut('slow');
}

$(document).ready(function () {
  window.setTimeout(preloader_fade(), 500); //call fade in .5 seconds
}
);

$(document).ready(function () {
  // Add minus icon for collapse element which is open by default
  $('.collapse.show').each(function () {
    $(this)
      .prev('.card-header')
      .find('.icon_faq')
      .addClass('icofont-minus')
      .removeClass('icofont-plus');
  });

  // Toggle plus minus icon on show hide of collapse element
  $('.collapse').on('show.bs.collapse', function () {
    $(this).prev('.card-header').find('.icon_faq').removeClass('icofont-plus').addClass('icofont-minus');
  })
    .on('hide.bs.collapse', function () {
      $(this).prev('.card-header').find('.icon_faq').removeClass('icofont-minus').addClass('icofont-plus');
    });

  $('.collapse').on('show.bs.collapse', function () {
    $(this).prev('.card-header').children('h2').children('.btn').addClass('active');
  })
    .on('hide.bs.collapse', function () {
      $(this).prev('.card-header').children('h2').children('.btn').removeClass('active');
    });
});

// Fix Header Js
$(window).scroll(function () {
  if ($(window).scrollTop() >= 250) {
    $('header').addClass('fix_style');
  }
  else {
    $('header').removeClass('fix_style');
  }
  if ($(window).scrollTop() >= 260) {
    $('header').addClass('fixed');
  }
  else {
    $('header').removeClass('fixed');
  }
});

// Close btn on click 

$(document).ready(function () {
  $('.navbar-toggler').click(function () {
    if ($(this).children('span').children('.ico_menu').hasClass('icofont-navigation-menu')) {
      $(this).children('span').children('.ico_menu').removeClass('icofont-navigation-menu').addClass('icofont-close');
    } else {
      $(this).children('span').children('.ico_menu').removeClass('icofont-close').addClass('icofont-navigation-menu');
    }
  });
});

(function () {
  $('.toggle-wrap').on('click', function () {
    $(this).toggleClass('active');
    $('aside').animate({ width: 'toggle' }, 200);
  });
})();
