import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

//importnig some required components
const App = lazy(() => import('./App'));
import Loader from './Pages/Commen/Loader';

//importing some required css files.
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Assets/css/style.css';
import './Assets/css/responsive.css';

//importing some required JS files
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './Assets/js/main';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<Loader/>}>
    <BrowserRouter>
      <ToastContainer/>
      <App />
    </BrowserRouter>
  </Suspense>
);
